/* eslint-disable */
import React from "react";
import SpotifyPlayer from "react-spotify-player";

// size may also be a plain string using the presets 'large' or 'compact'
const size = {
  width: "100%",
  height: 300,
};
const view = "list"; // or 'coverart'
const theme = "black"; // or 'white'

<SpotifyPlayer
  uri="spotify:album:1TIUsv8qmYLpBEhvmBmyBk"
  size={size}
  view={view}
  theme={theme}
/>;

function Musikk() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/6wDCljL66lVeTuLMYHPq8H?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/0vv82ESkNXyumDrfPBaJyv?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/0SmZGp1k2Qs6y4TaQSge2S?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/038jJhTNvARD4FnunEPSuU?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/7erAAsYiVp6ZuutVLcds30?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/6foPit1EuCL2Gnv71E2cPH?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/6S9A6qfAAdz71yGydmxkkJ?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/3IS5jmI1vA7cEgRFUOOGi8?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/1lD5N6eLHZttJqvn65osPR?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/42fF8HAO7Q5oe9BfO7uWIh?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/61HgpFoVAkw7I82CYACdfx?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/2OKc7peHYWTDNwTnyfZI6y?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/7mCxVHQ4abl9bEpeqJboza?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/6DueYCftsBmSLRJmqNSmaW?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/0mwBbhqVLedRhlPIsRsSzf?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/3hcghAwmP3ap5EPP2sSrHd?utm_source=generator"
            width="100%"
            height="152"
            frameBorder="0"
            title="Spotify Playlist"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
}

export default Musikk;
