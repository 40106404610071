/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import innlegg2 from "../../../assets/innlegg/innlegg2/innlegg2-31.png";
function Innlegg2() {
  return (
    <div className="col-lg-12 mb-5">
      <article className="blog_item">
        <div className="blog_item_img">
          <img className="card-img-2 rounded-0" src={innlegg2} alt="" />
          <div className="blog_item_date text-center">
            <h3>01. Apr</h3>
            <p>2024</p>
          </div>
        </div>
        <div className="blog_details">
          <Link to="/innlegg2" className="d-inline-block">
            <h2 className="blog-head" style={{ color: "#2d2d2d" }}>
              Å kaste loss: En sommer med frihet
            </h2>
          </Link>
          <p>Da jeg kjøpte min første seilbåt</p>
          <ul className="blog-info-link ps-0">
            <li>
              <i className="bi bi-tags" />
              Opplevelser
            </li>
            <li>
              <i className="bi bi-person-fill" />
              Sultan Avtajev
            </li>
            <li>
              <i className="bi bi-stopwatch" />8 min
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
}

export default Innlegg2;
