/* eslint-disable */
import React from "react";
import "./Prosjekter.css";
import prosjekt1 from "../../assets/prosjekt/Prosjekt1.png";
import prosjekt2 from "../../assets/prosjekt/Prosjekt2.png";
import prosjekt3 from "../../assets/prosjekt/Prosjekt3.png";
import prosjekt4 from "../../assets/prosjekt/Prosjekt4.png";
import prosjekt5 from "../../assets/prosjekt/Prosjekt5.png";
import prosjekt6 from "../../assets/prosjekt/Prosjekt6.png";
import prosjekt7 from "../../assets/prosjekt/Prosjekt7.png";
import prosjekt8 from "../../assets/prosjekt/Prosjekt8.png";
import prosjekt9 from "../../assets/prosjekt/Prosjekt9.png";
import prosjekt10 from "../../assets/prosjekt/Prosjekt10.png";
import prosjekt11 from "../../assets/prosjekt/Prosjekt11.png";
import prosjekt12 from "../../assets/prosjekt/Prosjekt12.png";
import prosjekt13 from "../../assets/prosjekt/Prosjekt13.png";
import prosjekt14 from "../../assets/prosjekt/Prosjekt14.png";
import prosjekt15 from "../../assets/prosjekt/Prosjekt15.png";

function Prosjekter() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div id="projects" className="basic-3">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 text-dark mx-auto">
                  <h2 className="h2-heading text-center">Utførte prosjekter</h2>
                  <p className="p-heading">
                    Her er noen av de mest representative prosjektene jeg har
                    jobbet med. De spenner fra enkel webdesign for
                    presentasjonsnettsteder til avansert webutvikling med
                    databaser.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-dark">
                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/kino">
                        <img
                          className="img-fluid image-cover"
                          src={prosjekt1}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>Kunde:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> JavaScript-applikasjon som
                      registrerer kjøp av kinobilletter. Programmert ved bruk av
                      Spring Boot og Java. Stylet med Bootstrap.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML, CSS, JavaScript, Spring
                      Boot og Java{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/kino"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/matlab">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt2}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Løsning av fysikk og kjemi
                      oppgaver med MatLab kode{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML og MATLAB{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/matlab"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/simpleperf">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt3}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Klient og server applikasjon
                      som er et verktøy for å måle nettverksgjennomstrømming.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Python{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/simpleperf"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/mattemoro">
                        <img
                          className="img-fluid img-cover "
                          src={prosjekt4}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> MatteMoro er en morsom og enkel
                      app som hjelper barn med å få teken på addisjon.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Android og Java{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/mattemoro"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/planapp">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt5}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> PlanApp er designet for å
                      hjelpe brukere med å holde styr på sine avtaler, samtidig
                      som den gir muligheten til å sende automatiske påminnelser
                      til venner, familie eller kollegaer.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Android og Java{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/planapp"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/mymaps">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt6}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> MyMaps reiseledsager designet
                      for å assistere brukere i å oppdage og utforske nye og
                      interessante steder, hvor enn de måtte være.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Android og Java{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/mymaps"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/booking">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt7}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Studentprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Applikasjon som er designet for
                      å tilrettelegge en rekke funksjoner som er viktige for
                      eiendomsutleie -og forvaltning.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML, CSS, JavaScript, .NET
                      6.0, C# og React{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/booking"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://github.com/sultanavtajev/cvprosjekt">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt8}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Hobbyprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Personlig portfolio for meg
                      selv. Tiltenkt som en "flytende" CV.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML, JavaScript, CSS, React
                      og Bootstrap{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/cvprosjekt"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://bypaolla.com">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt9}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Paolla Miranda Da Silva (blogger og
                      content creator)
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Personlig portfolio for Paolla
                      som inneholder blogg, portfolio og dokumentasjon om
                      utførte prosjekter.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML, JavaScript, CSS og
                      Next.js{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://bypaolla.com">
                        bypaolla.com
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/bypaolla"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://bachelor-7e242.web.app/">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt10}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Bacheloroppgave
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Webapplikasjon for
                      bachelorprosjekt hos Kongsberggruppen. Dette er et
                      dashbord som henter og presenterer data fra en mengde
                      databaser på backend. Databasene mottar data fra diverse
                      maskiner som sender data. Merk at det tar noen sekunder
                      før dataene lastes. Bruk brukernavn: test123@test.no og
                      passord: test123{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> HTML, JavaScript, CSS,
                      Next.js, Firebase og Github Functions{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a
                        className="blue"
                        href="https://bachelor-7e242.web.app/"
                      >
                        Dashbord
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/dashbord"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://smaffelutions.com">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt11}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> IT-Innovasjons- og
                      entreprenørskapsprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Oppbyging av et helt nytt
                      selskap, design, nettbutikk og markedsføring for et lite
                      bakeri som jeg har startet i samarbeid med ei
                      ernæringsfysiolog. Ikke live enda. Bruk passord: smaffel{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Figma, HTML, JavaScript, CSS,
                      Next.js, Shopify Hydrogen{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://smaffelutions.com">
                        smaffelutions.com
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://moody.no">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt12}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Hobbyprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> En plattform der brukere kan la
                      en AI analysere bilder. Bildene blir lastet opp av
                      brukeren, sendt til OpenAI sine bilde-modeller for analyse
                      og resultatet presenteres for brukeren. Konsept som enkelt
                      kan rulles ut som en aktiv tjeneste med abonnement.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Shadcn, HTML, JavaScript, CSS,
                      Next.js{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://moody.no">
                        Bildeanalyse
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/insta"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://perstest.vercel.app/">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt13}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Hobbyprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> En plattform der brukere kan
                      gjennomføre en personlighetstest. Testen består av 70
                      spørsmål. Apllikasjonen bruker matematiske modeller til å
                      regne seg frem til riktig resultat basert på svar fra
                      brukeren. Konsept som enkelt kan rulles ut som en aktiv
                      tjeneste med abonnement.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Shadcn, HTML, JavaScript, CSS,
                      Next.js{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://perstest.vercel.app/">
                        Personlighetstest
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/perstest"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://orakel-xi.vercel.app/">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt14}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Hobbyprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> En plattform der brukere kan få
                      spådommer generert av kunstig intelligens. Datagrunnlaget
                      består av et titals spørsmål delt inn i to kategorier.
                      OpenAi sine modeller brukes til å generere spådommene
                      basert på registrerte svar fra brukeren. Konsept som
                      enkelt kan rulles ut som en aktiv tjeneste med abonnement.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Shadcn, HTML, JavaScript, CSS,
                      Next.js{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://orakel-xi.vercel.app/">
                        Spådommer
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/orakel"
                      >
                        Github
                      </a>
                    </p>
                  </div>

                  <div className="text-container">
                    <div className="image-container">
                      <a href="https://stockimg.vercel.app/">
                        <img
                          className="img-fluid img-cover"
                          src={prosjekt15}
                          alt="alternative"
                        />
                      </a>
                    </div>
                    <p className="mb-0">
                      <strong>For:</strong> Hobbyprosjekt
                    </p>
                    <p className="mb-0">
                      <strong>Prosjekt:</strong> Dette er en full-stack
                      applikasjon som integrerer OpenAI's ChatGPT 4o og DALL-E
                      bildegenereringstjeneste med Cloudinary for lagring og
                      administrasjon av bilder. Brukere kan generere bilder
                      basert på egendefinerte tekstprompter eller
                      forhåndsdefinerte prompter. Applikasjonen laster opp
                      genererte bilder til Cloudinary, hvor de sorteres og
                      administreres. Konsept som enkelt kan rulles ut som en
                      aktiv tjeneste med abonnement.{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Teknologi:</strong> Shadcn, HTML, JavaScript, CSS,
                      Next.js, Cloudinary{" "}
                    </p>
                    <p className="mb-0">
                      <strong>Lenke: </strong>
                      <a className="blue" href="https://stockimg.vercel.app/">
                        AI-bilder
                      </a>
                    </p>
                    <p className="mb-0">
                      <strong>Kode: </strong>
                      <a
                        className="blue"
                        href="https://github.com/sultanavtajev/stockimg"
                      >
                        Github
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Prosjekter;
