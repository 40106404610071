/* eslint-disable */
import React from "react";
import { Timeline, Event } from "react-timeline-scribble";

function Utdanning() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="col-md-12 mb-4 text-dark">
              <Timeline>
                <Event
                  interval={"2024 – 2026"}
                  title={"OsloMet"}
                  subtitle={"Sivilingeniør/ Master"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 0969, Oslo
                  </p>
                  <p className="pb-3">
                    <strong>Notater:</strong> Anvendt kunstig intelligens
                  </p>
                  <p className="pb-1">
                    <strong>Fag:</strong>
                    <br />
                    ACIT4100 Understanding and Communicating Research
                    <br />
                    ACIT4610 Evolutionary Artificial Intelligence and Robotics
                    <br />
                    ACIT4620 Computational Intelligence
                    <br />
                  </p>
                </Event>

                <Event
                  interval={"2021 – 2024"}
                  title={"OsloMet"}
                  subtitle={"Dataingeniør"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 0969, Oslo
                  </p>
                  <p className="pb-3">
                    <strong>Notater:</strong> Dataingeniør/ Bachelor
                  </p>
                  <p className="pb-1">
                    <strong>Fag:</strong>
                    <br />
                    TRE1200 Fysikk for ingeniørfag
                    <br />
                    TRE1100 Matematikk for ingeniørfag
                    <br />
                    TRFE1000 Matematikk 1000
                    <br />
                    DAPE1300 Diskret matematikk
                    <br />
                    DAPE1400 Programmering
                    <br />
                    DATA1100 Teknologi og samfunn for programmerere
                    <br />
                    DATA1200 Webutvikling og inkluderende design
                    <br />
                    DATA1700 Webprogrammering
                    <br />
                    DATA1500 Databaser
                    <br />
                    DAPE2000 Matematikk 2000
                    <br />
                    DAFE2200 Systemutvikling
                    <br />
                    DATS2300 Algoritmer og datastrukturer
                    <br />
                    DAPE2101 Fysikk og kjemi
                    <br />
                    DATA2410 Datanettverk og skytjenester
                    <br />
                    DATA2500 Operativsystemer
                    <br />
                    DAVE3600 Apputvikling
                    <br />
                    ITPE3100 Datasikkerhet
                    <br />
                    ITPE3200 Webapplikasjoner
                    <br />
                    DATA3740 IT-Innovasjons- og entreprenørskapsprosjekt (Design
                    og utvikling av nettbutikk for Smaffelutions AS)
                    <br />
                    DATA3900 Bacheloroppgave (Innen sikkerhet hos Kongsberg
                    Gruppen AS)
                    <br />
                  </p>
                </Event>
                <Event
                  interval={"2013 – 2014"}
                  title={"Høgskolen i Oslo og Akershus"}
                  subtitle={"Forkurs til ingeniørutdanning"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 0969, Oslo
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Matematikk og fysikk
                  </p>
                </Event>
                <Event
                  interval={"2012 – 2013"}
                  title={"Nes Videregående Skole"}
                  subtitle={"Påbygg til generell studiekompetanse"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Vg4
                  </p>
                </Event>
                <Event
                  interval={"2011 – 2012"}
                  title={"Nes Videregående Skole"}
                  subtitle={"Dataelektronikerfaget"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-3">
                    <strong>Notater:</strong> Vg3
                  </p>
                  <p className="pb-1">
                    <strong>Fag:</strong>
                    <br />
                    Data- og elektronikksystemer
                    <br />
                    Data- og elektronikksystemer
                    <br />
                    Elektronisk infrastruktur
                    <br />
                    Data- og elektronikksystemer 2<br />
                    Elenergisystemer
                    <br />
                    Automatiseringssystemer
                    <br />
                    Entreprenørskap og bedriftsutvikling
                    <br />
                  </p>
                </Event>
                <Event
                  interval={"2010 – 2011"}
                  title={"Nes Videregående Skole"}
                  subtitle={"Data og Elektronikk"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Vg2
                  </p>
                </Event>
                <Event
                  interval={"2009 – 2010"}
                  title={"Nes Videregående Skole"}
                  subtitle={"Elektrofag"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Vg1
                  </p>
                </Event>
                <Event
                  interval={"2006 – 2009"}
                  title={"Runni Ungdomsskole"}
                  subtitle={"Student"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Fordypning i idrett
                  </p>
                </Event>
              </Timeline>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Utdanning;
