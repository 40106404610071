/* eslint-disable */
import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const Gjøremål2024 = () => {
  return (
    <div className="col-md-12">
      <BarChart
        xAxis={[
          {
            label: "2024",
            scaleType: "band",
            data: [
              "Januar",
              "Februar",
              "Mars",
              "April",
              "Mai",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "Desember",
            ],
          },
        ]}
        series={[
          { label: "Gjennomført 2024", data: [346, 276, 243] },
          {
            label: "Planlagt 2024",
            data: [333, 333, 333, 333, 333, 333, 333, 333, 333, 333, 333, 333],
          },
        ]}
        height={300}
        yAxis={[
          {
            label: "Antall gjøremål",
          },
        ]}
      />
      <p className="mb-0 text-black">
        <strong>Data:</strong> Antall gjøremål gjennomført og planlagt for 2024
      </p>
      <p className="mb-0 text-black">
        <strong>Mål:</strong> Gjennomføre 4000 gjøremål i 2024
      </p>
    </div>
  );
};

export default Gjøremål2024;
