/* eslint-disable */
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Meny from "./components/Meny/Meny";

import Meg from "./pages/Meg/Meg";
import Blogg from "./pages/Blogg/Blogg";
import Erfaring from "./pages/Erfaring/Erfaring";
import Ferdigheter from "./pages/Ferdigheter/Ferdigheter";
import Interesse from "./pages/Interesse/Interesse";
import Kontakt from "./pages/Kontakt/Kontakt";
import Status from "./pages/Status/Status";
import Prosjekter from "./pages/Prosjekter/Prosjekter";
import Utdanning from "./pages/Utdanning/Utdanning";
import Nedlasting from "./pages/Nedlasting/Nedlasting";
import Bøker from "./pages/Interesse/Bøker";
import Dokumentarer from "./pages/Interesse/Dokumentarer";
import Musikk from "./pages/Interesse/Musikk";
import Reise from "./pages/Interesse/Reise";
import Avis from "./pages/Interesse/Avis";
import Innlegg1a from "./pages/Blogg/Innlegg/Innlegg1a";
import Innlegg2a from "./pages/Blogg/Innlegg/Innlegg2a";
import Takk from "./pages/Kontakt/Takk";

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

function App() {
  useEffect(() => {
    ReactGA.initialize("G-DW4H01X76B");
    ReactGA.send("pageview");
  }, []);
  return (
    <Router>
      <Meny />
      <RouteTracker />
      <Routes>
        <Route path="/" element={<Meg />} />
        <Route path="/blogg" element={<Blogg />} />
        <Route path="/erfaring" element={<Erfaring />} />
        <Route path="/ferdigheter" element={<Ferdigheter />} />
        <Route path="/interesse" element={<Interesse />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/status" element={<Status />} />
        <Route path="/prosjekter" element={<Prosjekter />} />
        <Route path="/utdanning" element={<Utdanning />} />
        <Route path="/nedlasting" element={<Nedlasting />} />
        <Route path="/bøker" element={<Bøker />} />
        <Route path="/dokumentarer" element={<Dokumentarer />} />
        <Route path="/musikk" element={<Musikk />} />
        <Route path="/reise" element={<Reise />} />
        <Route path="/avis" element={<Avis />} />
        <Route path="/takk" element={<Takk />} />
        <Route path="/innlegg1" element={<Innlegg1a />} />
        <Route path="/innlegg2" element={<Innlegg2a />} />
      </Routes>
    </Router>
  );
}

function RouteTracker() {
  usePageViews();
  return null;
}

export default App;
