/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SocialIcons from "./Social.js";

function Meny() {
  const [menyTrykket, setMenyStatus] = useState(false);
  const menyRef = useRef();

  const handleOutsideClick = (event) => {
    if (
      menyTrykket &&
      menyRef.current &&
      !menyRef.current.contains(event.target)
    ) {
      setMenyStatus(false);
    }
  };

  const handleScroll = () => {
    setMenyStatus(false);
  };

  const endreMenyStatus = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMenyStatus(!menyTrykket);

    setTimeout(() => {
      // Forsinker tillegg av event listener
      document.addEventListener("click", handleOutsideClick);
    }, 0);
  };

  const location = useLocation();

  const checkActive = (path) =>
    location.pathname === path ? "colorlib-active" : "";

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log("Oppdatert tilstand av menyTrykket:", menyTrykket);

    if (menyTrykket) {
      document.body.classList.add("menytrykket");
    } else {
      document.body.classList.remove("menytrykket");
    }

    return () => {
      document.body.classList.remove("menytrykket");
    };
  }, [menyTrykket]);

  const menuItems = [
    { path: "/", label: "meg" },
    { path: "/erfaring", label: "erfaring" },
    { path: "/utdanning", label: "utdanning" },
    { path: "/ferdigheter", label: "ferdigheter" },
    { path: "/prosjekter", label: "prosjekter" },
    { path: "/interesse", label: "interesser" },
    { path: "/status", label: "status" },
    { path: "/blogg", label: "blogg" },
    { path: "/kontakt", label: "kontakt" },
    { path: "/nedlasting", label: "nedlasting" },
  ];

  return (
    <div id="colorlib-page">
      <button
        className={`colorlib-nav-toggle ${menyTrykket ? "active" : ""}`}
        onClick={endreMenyStatus}
      >
        <i />
      </button>
      <aside ref={menyRef} id="colorlib-aside">
        <nav id="colorlib-main-menu" role="navigation">
          <ul>
            {menuItems.map((item) => (
              <li key={item.path} className={checkActive(item.path)}>
                <NavLink to={item.path} className="link">
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <SocialIcons />
        <div className="colorlib-footer mt-3">
          <p className="text-dark mb-0">
            <small>Designet og kodet av:</small>
          </p>
          <p className="text-dark mt-0">
            <small> &copy; 2024 Sultan Avtajev</small>
          </p>
        </div>
      </aside>
    </div>
  );
}

export default Meny;
