/* eslint-disable */
import Accordion from "react-bootstrap/Accordion";
import "./Accordion.css";

function AccordionLevel() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>1. Nybegynner</Accordion.Header>
        <Accordion.Body>
          På dette nivået har jeg grunnleggende kjennskap. Jeg er bevisst på
          språket eller rammeverket og kjenner til noen av de grunnleggende
          konseptene, men jeg har ikke praktisk erfaring.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>2. Grunnleggende</Accordion.Header>
        <Accordion.Body>
          Her har jeg litt praktisk erfaring. Jeg kan skrive enkel kode eller
          bruke grunnleggende funksjoner av rammeverket, men jeg trenger ofte
          hjelp eller må referere til dokumentasjon.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>3. Kompetent</Accordion.Header>
        <Accordion.Body>
          På dette nivået har jeg god forståelse og kan utføre de fleste
          oppgaver selvstendig. Jeg kan løse vanlige problemer og har en solid
          forståelse av språkets eller rammeverkets særegenheter.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>4. Avansert</Accordion.Header>
        <Accordion.Body>
          Jeg har dyp kunnskap og erfaring. Jeg kan anvende språket eller
          rammeverket effektivt, optimalisere kode, benytte avanserte funksjoner
          og bidra til prosjekter med høy kompleksitet.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>5. Ekspert</Accordion.Header>
        <Accordion.Body>
          Som ekspert har jeg omfattende og detaljert kunnskap. Jeg kan
          undervise andre, bidra til utviklingen av språket eller rammeverket,
          og løse komplekse og unike problemer.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionLevel;
