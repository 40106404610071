/* eslint-disable */
import React from "react";
import "./ProgressBar.css";

const SKILL_LEVELS = {
  Nybegynner: 20,
  Grunnleggende: 40,
  Kompetent: 60,
  Avansert: 80,
  Ekspert: 100,
};

function ProgressBar({ skill, level }) {
  const barStyle = {
    width: `${SKILL_LEVELS[level]}%`,
    backgroundColor: "black",
  };

  return (
    <div className="skill-bar">
      <div className="skill-name">{skill}</div>
      <div className="skill-progress">
        <div className="progress" style={barStyle}></div>
      </div>
    </div>
  );
}
export default ProgressBar;
