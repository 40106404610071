/* eslint-disable */
import React from "react";
import innlegg1 from "../../../assets/innlegg/innlegg1.jpg";
import sitaterData from "../../../data/Anekdoter.json";

function Innlegg1a() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="col-lg-12 posts-list">
              <div className="single-post">
                <div className="feature-img">
                  <img className="img-fluid" src={innlegg1} alt="" />
                </div>
                <div className="blog_details">
                  <h1 style={{ color: "#2d2d2d" }}>Diogenes fra Sinope</h1>
                  <ul className="blog-info-link ps-0 mt-3 mb-5">
                    <li>
                      <i className="bi bi-tags" />
                      Filosofi
                    </li>
                    <li>
                      <i className="bi bi-person-fill" />
                      Sultan Avtajev
                    </li>
                    <li>
                      <i className="bi bi-stopwatch" />
                      17 min
                    </li>
                  </ul>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Jeg leter etter en ærlig man
                  </h2>
                  <p className="excert mt-1">
                    I hjertet av antikkens Athen, blant de mange filosofene som
                    har formet vår tenkemåte, står Diogenes fra Sinope ut som et
                    lysende eksempel på kontrast og provokasjon. Kjent som
                    hundens filosof, grunnla han den kyniske filosofien som
                    utfordrer moderne begreper om velstand, suksess, og lykke. I
                    en verden preget av materialisme og en uendelig søken etter
                    mer, fremstår Diogenes' læresetninger som en frisk bris av
                    enkelhet og autentisitet.
                  </p>
                  <p className="excert mt-1">
                    Diogenes er kanskje best husket for sitt dristige stunt med
                    en lanterne i dagslys, hvor han vandret gjennom Athen på
                    leting etter en ærlig mann. Dette bildet av Diogenes, med
                    sin lanterne i hånden, utfordrer oss til å reflektere over
                    vår egen integritet og de sosiale maskene vi bærer. Hans
                    berømte sitat, "Jeg leter etter en mann," er ikke bare en
                    anekdote fra antikken, men også et tidløst rop etter sannhet
                    og ekthet i menneskelige relasjoner.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>Diogenes blir til</h2>
                  <p className="excert mt-1">
                    Diogenes fra Sinope, en av de mest fargerike og
                    kontroversielle figurene i antikkens filosofi, ble født
                    rundt 404 f.Kr. i byen Sinope, på kysten av Svartehavet, i
                    det som nå er Tyrkia. Historien forteller at han ble forvist
                    fra Sinope etter å ha blitt involvert i en skandale knyttet
                    til devaluering av mynt, en handling han muligens deltok i
                    sammen med sin far. Denne forvisningen skulle bli
                    vendepunktet i hans liv, da det ledet ham på en sti mot en
                    radikal omfavnelse av kynisk filosofi.
                  </p>
                  <p className="excert mt-1">
                    Da Diogenes ankom Athen, brakte han med seg en filosofi som
                    var djerv og provoserende, utfordrende etablerte normer og
                    verdier. Kynismen, som han kom til å bli mest assosiert med,
                    var ikke bare en filosofisk retning, men en livsstil.
                    Kynikerne forfektet en tilværelse som var i tråd med
                    naturen, fri fra samfunnets overflødigheter og falskhet. De
                    prioriterte personlig frihet og selvtilstrekkelighet, og
                    argumenterte for at lykke ikke kunne oppnås gjennom
                    materielle goder eller sosial status, men gjennom å leve et
                    liv i dyd i overensstemmelse med naturen.
                  </p>
                  <p className="excert mt-1">
                    For Diogenes betydde dette å forkaste konvensjonell
                    oppførsel og materiell besittelse til fordel for en enkelhet
                    som ofte grenset til det ekstreme. Han bodde i en tønne
                    (eller krukke) i stedet for et hus, og eide kun det mest
                    nødvendige. Diogenes praktiserte hva han forkynte, og hans
                    daglige liv var en levende demonstrasjon av hans filosofiske
                    overbevisninger. Hans handlinger, ofte sett på som
                    sjokkerende eller uvanlige, var ment å provosere til
                    ettertanke og utfordre publikum til å revurdere deres egne
                    verdier og oppførsel.
                  </p>
                  <p className="excert mt-1">
                    Kynismens grunnleggende prinsipper, som Diogenes levde
                    etter, inkluderte forakt for konvensjonell visdom, en
                    avvisning av sosiale normer, og en tro på at lykke oppnås
                    gjennom selvkontroll og uavhengighet fra ytre ønsker.
                    Diogenes' legendariske forakt for materiell rikdom, politisk
                    makt, og sosial prestisje, sammen med hans skarpe vidd og
                    evne til å avsløre hykleri, har gjort ham til en varig figur
                    i filosofiens historie.
                  </p>
                  <p className="excert mt-1">
                    Diogenes’ liv og filosofi står som en kraftig påminnelse om
                    kynismens kjerneideer og dens relevans, selv i dagens
                    samfunn, hvor spørsmål om autentisitet, materialisme, og
                    personlig frihet fortsatt er høyst aktuelle. Ved å utforske
                    Diogenes’ tilnærming til livet, inviteres vi til å vurdere
                    hvordan vi selv lever i forhold til våre egne verdier og
                    overbevisninger.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Diogenes filosofiske lærdommer
                  </h2>
                  <h5 style={{ color: "#2d2d2d" }}>Enkelhet i livsførsel</h5>
                  <p className="excert mt-1">
                    Diogenes fra Sinope er kanskje det ultimate symbolet på
                    enkelhet i livsførsel og frivillig fattigdom. Hans
                    beslutning om å leve i en tønne, snarere enn i et
                    tradisjonelt hjem, og hans minimale eiendeler, understreket
                    hans filosofiske overbevisning om at ekte lykke ikke
                    avhenger av materiell rikdom eller komfort. Diogenes
                    argumenterte for at ved å redusere våre fysiske behov til
                    det absolutt nødvendige, kunne vi frigjøre oss selv fra
                    mange av samfunnets forventninger og press, og dermed oppnå
                    en større grad av personlig frihet og lykke. I dagens
                    samfunn, hvor forbruk og overflod ofte anses som tegn på
                    suksess, utfordrer Diogenes’ tilnærming oss til å revurdere
                    vår forståelse av nødvendighet og lykke. Hans praksis kan
                    inspirere en bevegelse mot minimalisme, en livsstil som
                    verdsetter enkelhet og bevisst forbruk over materialistisk
                    akkumulasjon.
                  </p>
                  <h5 style={{ color: "#2d2d2d" }}>Kritikk av konvensjoner</h5>
                  <p className="excert mt-1">
                    Diogenes’ liv var en konstant utfordring av sosiale
                    konvensjoner og normer. Han stilte spørsmål ved samfunnets
                    kollektive visdom og avviste ideen om at status og rikdom
                    var tegn på moralisk overlegenhet. Ved å leve i direkte
                    opposisjon til de rådende normene, demonstrerte Diogenes en
                    kritisk holdning til sosialt aksepterte verdier og
                    oppfordret andre til å gjøre det samme. Hans forakt for
                    materiell velstand og sosial prestisje var ikke bare en
                    personlig preferanse, men en grunnleggende kritikk av
                    samfunnets prioriteringer. I en tid preget av sosial media,
                    statussymboler, og konstant sammenligning, kan Diogenes’
                    avvisning av disse verdiene tjene som en påminnelse om
                    verdien av autentisitet og selvtilstrekkelighet.
                  </p>
                  <h5 style={{ color: "#2d2d2d" }}>Ærlighet og direkthet</h5>
                  <p className="excert mt-1">
                    Diogenes var berømt for sin ærlighet og direkte
                    kommunikasjon. Han fryktet ikke konfrontasjon og brukte sin
                    vidd og intelligens til å avsløre hykleri og selvbedrag.
                    Diogenes’ ubøyelige forpliktelse til sannheten, selv når den
                    var ubehagelig eller upopulær, viser verdien av åpenhet og
                    ærlighet i menneskelige relasjoner. I en verden hvor
                    politisk korrekthet ofte dominerer og ærlige meninger kan
                    bli undertrykt av frykt for å støte, minner Diogenes oss om
                    betydningen av å uttrykke våre sanne tanker og følelser.
                    Dette prinsippet om åpenhet er spesielt relevant i dagens
                    samfunn, hvor ærlig dialog og genuin kommunikasjon kan bygge
                    broer og fremme forståelse på tvers av kulturelle og
                    ideologiske skillelinjer.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Diogenes mest minneverdige anekdoter
                  </h2>
                  <h5 style={{ color: "#2d2d2d" }}>
                    Møtet med Aleksander den store
                  </h5>
                  <p className="excert mt-1">
                    En av de mest berømte anekdotene om Diogenes involverer hans
                    møte med Aleksander den Store. Aleksander, som beundret
                    Diogenes for hans rykte som filosof, fant ham solende seg og
                    spurte om det var noe han kunne gjøre for ham. Diogenes
                    svarte enkelt: "Ja, trå til side. Du står i sola for meg."
                    Denne utvekslingen illustrerer Diogenes’ likegyldighet
                    overfor makt og rikdom, samt hans dype forpliktelse til
                    selvtilstrekkelighet og uavhengighet. Selv i møte med
                    verdens mektigste mann, ønsket Diogenes ikke mer enn det han
                    allerede hadde – friheten til å nyte solen. Denne historien
                    underviser i ydmykhet og understreker at ekte lykke og
                    tilfredshet ikke finnes i materiell rikdom eller sosial
                    status, men i å sette pris på de enkle gledene i livet.
                  </p>
                  <h5 style={{ color: "#2d2d2d" }}>
                    Søket etter en ærlig mann
                  </h5>
                  <p className="excert mt-1">
                    Diogenes er også kjent for å vandre gjennom Athens gater
                    midt på dagen med en lanterne, angivelig på leting etter en
                    ærlig mann. Denne handlingen var et levende symbol på hans
                    syn på menneskets natur og samfunnets moralske tilstand.
                    Gjennom denne anekdoten kritiserer Diogenes samfunnets
                    overfladiskhet og hykleri, og fremhever vanskeligheten ved å
                    finne ekte integritet og ærlighet. Denne historien
                    oppfordrer til selvrefleksjon og utfordrer oss til å vurdere
                    vår egen ærlighet og autentisitet i våre daglige liv.
                  </p>
                  <h5 style={{ color: "#2d2d2d" }}>Livet i en tønne</h5>
                  <p className="excert mt-1">
                    Diogenes’ valg om å bo i en tønne (eller en stor
                    keramikkrukke) snarere enn i et tradisjonelt hjem er et
                    ytterligere bevis på hans forpliktelse til enkelhet og
                    avvisning av materielle goder. Denne livsstilen tjente som
                    et konkret eksempel på hans filosofi om at lykke ikke er
                    avhengig av ytre omstendigheter, men av en indre tilstand.
                    Ved å redusere sine fysiske behov til det mest
                    grunnleggende, demonstrerte Diogenes at frihet og
                    tilfredshet kan oppnås gjennom å omfavne livets enkelhet.
                    Denne anekdoten inviterer oss til å revurdere vårt eget
                    forhold til materielle goder og vurdere i hvilken grad vår
                    lykke er knyttet til besittelse og komfort.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Andre anekdoter om Diogenes
                  </h2>
                  {sitaterData.map((sitat, index) => (
                    <p key={index}>
                      {sitat.Nr}. {sitat.Sitat}
                    </p> // Anta at hvert objekt har en 'tekst' egenskap
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Innlegg1a;
