/* eslint-disable */
import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const Trening2023 = () => {
  return (
    <div className="col-md-12 mb-3">
      <BarChart
        xAxis={[
          {
            label: "2023",
            scaleType: "band",
            data: [
              "Januar",
              "Februar",
              "Mars",
              "April",
              "Mai",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "Desember",
            ],
          },
        ]}
        series={[
          {
            label: "Gjennomført 2023",
            data: [26, 13, 20, 4, 2, 20, 8, 25, 26, 26, 23, 24],
          },
          {
            label: "Planlagt 2023",
            data: [17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17],
          },
        ]}
        height={300}
        yAxis={[
          {
            label: "Antall treninger",
          },
        ]}
      />
      <p className="mb-0 text-black">
        <strong>Data:</strong> Antall treninger gjennomført og planlagt for 2023
      </p>
      <p className="mb-0 text-black">
        <strong>Mål:</strong> Gjennomføre 200 treninger i 2023
      </p>
      <p className="mb-0 text-black">
        <strong>Resultat:</strong> 2023 endte med 217 gjennomførte treninger
      </p>
    </div>
  );
};

export default Trening2023;
