/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import innlegg1 from "../../../assets/innlegg/innlegg1.jpg";

function Innlegg1() {
  return (
    <div className="col-lg-12 mb-5">
      <article className="blog_item">
        <div className="blog_item_img">
          <img className="card-img-1 rounded-0" src={innlegg1} alt="" />
          <div className="blog_item_date text-center">
            <h3>15. Jan</h3>
            <p>2024</p>
          </div>
        </div>
        <div className="blog_details">
          <Link to="/innlegg1" className="d-inline-block">
            <h2 className="blog-head" style={{ color: "#2d2d2d" }}>
              Diogenes fra Sinope
            </h2>
          </Link>
          <p>En av de filosofene jeg har fått mest glede av!</p>
          <ul className="blog-info-link ps-0">
            <li>
              <i className="bi bi-tags" />
              Filosofi
            </li>
            <li>
              <i className="bi bi-person-fill" />
              Sultan Avtajev
            </li>
            <li>
              <i className="bi bi-stopwatch" />
              17 min
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
}

export default Innlegg1;
