/* eslint-disable */
import React, { useState } from "react";
import "./Dokumentarer.css";
import dokumentarerData from "../../data/dok.json";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";

function Dokumentarer() {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("navn");

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const sortData = (array) => {
    function compare(a, b) {
      if (orderBy === "link") {
        // Hvis du trenger spesialhåndtering for linker eller andre ikke-alfabetiske data
        a = a[orderBy].toLowerCase(); // Anta at linkene er strenger
        b = b[orderBy].toLowerCase();
      } else {
        a = a[orderBy];
        b = b[orderBy];
      }

      if (a < b) {
        return orderDirection === "asc" ? -1 : 1;
      }
      if (a > b) {
        return orderDirection === "asc" ? 1 : -1;
      }
      return 0;
    }
    return array.sort(compare);
  };

  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "Navn"}
                      direction={orderDirection}
                      onClick={() => handleSortRequest("Navn")}
                    >
                      Navn
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "Produksjon"}
                      direction={orderDirection}
                      onClick={() => handleSortRequest("Produksjon")}
                    >
                      Produksjon
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    Link
                    {/* Sortering på URL kan legges til om nødvendig */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortData(dokumentarerData).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {row.Navn}
                    </TableCell>
                    <TableCell align="left">{row.Produksjon}</TableCell>
                    <TableCell align="left">
                      <a
                        href={row.Link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.Link}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </div>
  );
}

export default Dokumentarer;
