/* eslint-disable */
import React from "react";
import ProgressBar from "./ProgressBar.js";
import ProgressLabel from "./ProgressLabel.js";
import AccordionLevel from "./Accordion.js";
import "./Ferdigheter.css";

function Ferdigheter() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="col-md-12 mb-4 text-dark">
              {/* Legg til ProgressBar for hvert teknologi eller programmeringsspråk */}
              <div className="kv-ov mt-0">
                <div className="kv-ov-empty"></div>
                <div className="kv-ov-text">
                  <h2>
                    <strong>Programmeringsspråk</strong>
                  </h2>
                </div>
              </div>
              <ProgressLabel />
              <ProgressBar skill="HTML" level="Avansert" />
              <ProgressBar skill="CSS" level="Avansert" />
              <ProgressBar skill="JavaScript" level="Avansert" />
              <ProgressBar skill="SQL" level="Kompetent" />
              <ProgressBar skill="Java" level="Avansert" />
              <ProgressBar skill="Python" level="Kompetent" />
              <ProgressBar skill="C#" level="Grunnleggende" />
              <ProgressBar skill="XML" level="Grunnleggende" />
              <ProgressBar skill="MATLAB" level="Grunnleggende" />

              <div className="kv-ov">
                <div className="kv-ov-empty"></div>
                <div className="kv-ov-text">
                  <h2>
                    <strong>Rammeverk/biblioteker</strong>
                  </h2>
                </div>
              </div>
              <ProgressLabel />
              <ProgressBar skill="Next.js" level="Nybegynner" />
              <ProgressBar skill="Bootstrap" level="Avansert" />
              <ProgressBar skill="JQuery" level="Grunnleggende" />
              <ProgressBar skill="React.js" level="Grunnleggende" />

              <div className="kv-ov">
                <div className="kv-ov-empty"></div>
                <div className="kv-ov-text">
                  <h2>
                    <strong>Diverse</strong>
                  </h2>
                </div>
              </div>
              <ProgressLabel />
              <ProgressBar skill="Node.js" level="Grunnleggende" />
              <ProgressBar skill="Shopify" level="Grunnleggende" />
              <ProgressBar skill="Linux" level="Grunnleggende" />
              <ProgressBar skill="Docker" level="Nybegynner" />
              <ProgressBar skill="Powershell" level="Grunnleggende" />
              <ProgressBar skill="Android Studio" level="Grunnleggende" />
              <ProgressBar skill="Wordpress" level="Grunnleggende" />
              <ProgressBar skill="DevOps" level="Grunnleggende" />
              <ProgressBar skill="Git" level="Kompetent" />
              <ProgressBar skill="Firebase" level="Nybegynner" />
              <ProgressBar skill=".NET" level="Grunnleggende" />
              <ProgressBar skill="API-utvikling" level="Grunnleggende" />
              <ProgressBar skill="Unity Engine" level="Grunnleggende" />
              <ProgressBar skill="Adobe pakke" level="Grunnleggende" />
              <ProgressBar skill="Office pakke" level="Avansert" />

              <div className="kv-ov">
                <div className="kv-ov-empty"></div>
                <div className="kv-ov-text">
                  <h2>
                    <strong>Språk</strong>
                  </h2>
                </div>
              </div>

              <ProgressLabel />
              <ProgressBar skill="Norsk" level="Avansert" />
              <ProgressBar skill="Engelsk" level="Avansert" />
              <ProgressBar skill="Russisk" level="Avansert" />
              <ProgressBar skill="Tsjetsjensk" level="Avansert" />

              <div className="kv-ov">
                <div className="kv-ov-empty"></div>
                <div className="kv-ov-text">
                  <h2>
                    <strong>Nøkkelkvalifikasjoner</strong>
                  </h2>
                </div>
              </div>

              <ProgressLabel />
              <ProgressBar
                skill="Entreprenørskap og forretningsutvikling"
                level="Kompetent"
              />
              <ProgressBar
                skill="Investering og forvaltning"
                level="Avansert"
              />
              <ProgressBar
                skill="Prosjektledelse og prosjektstyring"
                level="Kompetent"
              />
              <ProgressBar
                skill="Lederskap og personalansvar"
                level="Avansert"
              />

              <ProgressBar
                skill="Produktutvikling og markedsføring"
                level="Kompetent"
              />

              <AccordionLevel />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Ferdigheter;
