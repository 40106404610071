/* eslint-disable */
import React from "react";
import "./Blogg.css";
import Innlegg1 from "./Innlegg/Innlegg1";
import Innlegg2 from "./Innlegg/Innlegg2";

function Blogg() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <Innlegg1 />
            <Innlegg2 />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blogg;
