/* eslint-disable */
import React from "react";
import Trening2024 from "./Trening2024";
import Trening2023 from "./Trening2023";
import Gjøremål2023 from "./Gjøremål2023";
import Gjøremål2024 from "./Gjøremål2024";

function Status() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="col-md-12 mb-4">
            <h1 className="h2 text-center">Ingen måling, ingen mestring!</h1>
            <p className="mb-0"> </p>
          </div>
          <div className="row">
            <Trening2023> </Trening2023>
            <Trening2024> </Trening2024>
            <Gjøremål2023> </Gjøremål2023>
            <Gjøremål2024> </Gjøremål2024>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Status;
