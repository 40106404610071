/* eslint-disable */
import React from "react";
import { useLocation } from "react-router-dom";
import "./Takk.css";

function Takk() {
  const location = useLocation();
  const { fulltnavn } = location.state || {}; // Hent navnet fra state, med fallback

  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="text-center">
            <h1 className="">Hei, {fulltnavn}!</h1>
            <h2>Takk for din henvendelse!</h2>
            <p>Jeg vil ta kontakt med deg så snart som mulig.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Takk;
