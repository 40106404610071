/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Innlegg2a.css";

// Importer bildene
import innlegg2_0 from "../../../assets/innlegg/innlegg2/innlegg2-0.jpg";
import innlegg2_1 from "../../../assets/innlegg/innlegg2/innlegg2-1.jpg";
import innlegg2_2 from "../../../assets/innlegg/innlegg2/innlegg2-2.jpg";
import innlegg2_3 from "../../../assets/innlegg/innlegg2/innlegg2-3.jpg";
import innlegg2_4 from "../../../assets/innlegg/innlegg2/innlegg2-4.jpg";
import innlegg2_5 from "../../../assets/innlegg/innlegg2/innlegg2-5.jpg";
import innlegg2_6 from "../../../assets/innlegg/innlegg2/innlegg2-6.PNG";
import innlegg2_7 from "../../../assets/innlegg/innlegg2/innlegg2-7.PNG";
import innlegg2_8 from "../../../assets/innlegg/innlegg2/innlegg2-8.PNG";
import innlegg2_9 from "../../../assets/innlegg/innlegg2/innlegg2-9.PNG";
import innlegg2_10 from "../../../assets/innlegg/innlegg2/innlegg2-10.PNG";
import innlegg2_11 from "../../../assets/innlegg/innlegg2/innlegg2-11.PNG";
import innlegg2_12 from "../../../assets/innlegg/innlegg2/innlegg2-12.PNG";
import innlegg2_13 from "../../../assets/innlegg/innlegg2/innlegg2-13.PNG";
import innlegg2_14 from "../../../assets/innlegg/innlegg2/innlegg2-14.PNG";
import innlegg2_15 from "../../../assets/innlegg/innlegg2/innlegg2-15.PNG";
import innlegg2_16 from "../../../assets/innlegg/innlegg2/innlegg2-16.JPG";
import innlegg2_17 from "../../../assets/innlegg/innlegg2/innlegg2-17.JPG";
import innlegg2_18 from "../../../assets/innlegg/innlegg2/innlegg2-18.JPG";
import innlegg2_19 from "../../../assets/innlegg/innlegg2/innlegg2-19.PNG";
import innlegg2_20 from "../../../assets/innlegg/innlegg2/innlegg2-20.PNG";
import innlegg2_21 from "../../../assets/innlegg/innlegg2/innlegg2-21.PNG";
import innlegg2_22 from "../../../assets/innlegg/innlegg2/innlegg2-22.PNG";
import innlegg2_23 from "../../../assets/innlegg/innlegg2/innlegg2-23.PNG";
import innlegg2_24 from "../../../assets/innlegg/innlegg2/innlegg2-24.PNG";
import innlegg2_25 from "../../../assets/innlegg/innlegg2/innlegg2-25.PNG";
import innlegg2_26 from "../../../assets/innlegg/innlegg2/innlegg2-26.PNG";
import innlegg2_27 from "../../../assets/innlegg/innlegg2/innlegg2-27.PNG";
import innlegg2_28 from "../../../assets/innlegg/innlegg2/innlegg2-28.PNG";
import innlegg2_29 from "../../../assets/innlegg/innlegg2/innlegg2-29.PNG";
import innlegg2_30 from "../../../assets/innlegg/innlegg2/innlegg2-30.PNG";
import innlegg2_31 from "../../../assets/innlegg/innlegg2/innlegg2-31.png";
import innlegg2_34 from "../../../assets/innlegg/innlegg2/innlegg2-34.png";
import innlegg2_36 from "../../../assets/innlegg/innlegg2/innlegg2-36.png";
import innlegg2_38 from "../../../assets/innlegg/innlegg2/innlegg2-38.png";
import innlegg2_43 from "../../../assets/innlegg/innlegg2/innlegg2-43.png";
import innlegg2_44 from "../../../assets/innlegg/innlegg2/innlegg2-44.png";
import innlegg2_46 from "../../../assets/innlegg/innlegg2/innlegg2-46.png";
import innlegg2_47 from "../../../assets/innlegg/innlegg2/innlegg2-47.png";
import innlegg2_48 from "../../../assets/innlegg/innlegg2/innlegg2-48.png";
import innlegg2_50 from "../../../assets/innlegg/innlegg2/innlegg2-50.png";

// Legg alle bildene inn i en array
const images = [
  innlegg2_0,
  innlegg2_1,
  innlegg2_2,
  innlegg2_3,
  innlegg2_4,
  innlegg2_5,
  innlegg2_6,
  innlegg2_7,
  innlegg2_8,
  innlegg2_9,
  innlegg2_10,
  innlegg2_11,
  innlegg2_12,
  innlegg2_13,
  innlegg2_14,
  innlegg2_15,
  innlegg2_16,
  innlegg2_17,
  innlegg2_18,
  innlegg2_19,
  innlegg2_20,
  innlegg2_21,
  innlegg2_22,
  innlegg2_23,
  innlegg2_24,
  innlegg2_25,
  innlegg2_26,
  innlegg2_27,
  innlegg2_28,
  innlegg2_29,
  innlegg2_30,
  innlegg2_31,
  innlegg2_34,
  innlegg2_36,
  innlegg2_38,
  innlegg2_43,
  innlegg2_44,
  innlegg2_46,
  innlegg2_47,
  innlegg2_48,
  innlegg2_50,
  ];

// Definerer en enkel stil for bildene for å sikre at de passer innenfor karusellens container.
const imgStyle = {
  width: "100%",
  objectFit: "cover", // Dette sørger for at bildene dekker tilgjengelig plass uten å miste proporsjonene sine
};

function ImageSlider() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    pauseOnHover: true,
  };

  return (
     <div>
      <h2> Noen bilder fra båten </h2>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Bilde ${index}`} style={imgStyle} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;
