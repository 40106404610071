/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import "./Interesse.css";
import interesse1 from "../../assets/interesse/Interesse1.jpg";
import interesse2 from "../../assets/interesse/Interesse2.jpg";
import interesse3 from "../../assets/interesse/Interesse3.jpg";
import interesse4 from "../../assets/interesse/Interesse4.jpg";
import interesse5 from "../../assets/interesse/Interesse5.jpg";
import interesse6 from "../../assets/interesse/Interesse6.jpg";
import interesse7 from "../../assets/interesse/Interesse7.jpg";
import interesse8 from "../../assets/interesse/Interesse8.jpg";
import interesse9 from "../../assets/interesse/Interesse9.jpg";

function Interesse() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="container px-4 px-lg-5">
            <div className="row gx-0 gy-0">
              <div className="col-lg-6">
                <Link to="/bøker" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Bøker</div>
                      <p className="mb-0">
                        <i
                          className="bi bi-arrow-right-circle-fill"
                          style={{ fontSize: "1rem" }}
                        ></i>{" "}
                        Sjekk ut bøkene jeg har lest
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse1} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/dokumentarer" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Dokumentarer</div>
                      <p className="mb-0">
                        <i
                          className="bi bi-arrow-right-circle-fill"
                          style={{ fontSize: "1rem" }}
                        ></i>{" "}
                        Sjekk ut dokumentarene jeg har sett
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse2} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/eiendomsinvesteringer" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Eiendomsinvestering</div>
                      <p className="mb-0">
                        Investert i eiendom siden jeg var 25 år.
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse3} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/entreprenørskap" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Entreprenørskap</div>
                      <p className="mb-0">
                        Drevet for meg selv siden jeg var 16 år.
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse4} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/trening" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Trening</div>
                      <p className="mb-0">Trener CrossFit</p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse5} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/bøker" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Økonomisk frihet</div>
                      <p className="mb-0">
                        {" "}
                        Passive inntekter har dekket mine utgifter siste 3 år.{" "}
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse6} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/musikk" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Musikk</div>
                      <p className="mb-0">
                        <i
                          className="bi bi-arrow-right-circle-fill"
                          style={{ fontSize: "1rem" }}
                        ></i>{" "}
                        Sjekk ut mine spillelister
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse7} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/reise" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Reise</div>
                      <p className="mb-0">
                        <i
                          className="bi bi-arrow-right-circle-fill"
                          style={{ fontSize: "1rem" }}
                        ></i>{" "}
                        Sjekk ut mine reiser
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse8} alt="..." />
                </Link>
              </div>

              <div className="col-lg-6">
                <Link to="/avis" className="portfolio-item">
                  <div className="caption">
                    <div className="caption-content">
                      <div className="h2 text-white">Avisinnlegg</div>
                      <p className="mb-0">
                        <i
                          className="bi bi-arrow-right-circle-fill"
                          style={{ fontSize: "1rem" }}
                        ></i>{" "}
                        Meg i media
                      </p>
                    </div>
                  </div>
                  <img className="img-fluid" src={interesse9} alt="..." />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Interesse;
