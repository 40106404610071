/* eslint-disable */
import React from "react";
import { Timeline, Event } from "react-timeline-scribble";

function Erfaring() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="col-md-12 mb-4 text-dark">
              {/* Timeline komponent her */}
              <Timeline>
                <Event
                  interval={"01.02.2024 - Nåværende"}
                  title={"Smaffelutions AS"}
                  subtitle={"Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2072, Dal
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Produksjon og salg av veganske bakevarer
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Smaffelutions er et selskap som produserer og selger veganske bakevarer. Selskapet har foreløpig en liten produksjonslinje for veganske bakevarer og selger disse til privatpersoner og kafeer. 
                  </p>
                </Event>
                <Event
                  interval={"01.02.2023 - Nåværende"}
                  title={"Birkelund Eiendomsutvikling AS"}
                  subtitle={"Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2072, Dal
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Utleie av hybler og
                    leiligheter
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Utleie av næringsbygg og
                    tilhørende bygninger på eiendommen. Birkelund er et bygg på
                    ca. 1400 kvm og inneholder 28 rom. Eiendommen er på 6 mål og
                    har en tilhørende enebolig og garasje. Her eier/driver jeg
                    stedet og utvikler eiendommen med nye konsepter.
                  </p>
                </Event>
                <Event
                  interval={"01.06.2022 - Nåværende"}
                  title={"Developia AS"}
                  subtitle={"Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 0969, Oslo
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Programmering og utvikling
                    av nettsider
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Programvareselskap som tilbyr
                    tjenester til bedrifter og det offentlige. Selskapet tilbyr
                    tjenester innen front-end, back-end, webutvikling,
                    rådgiving, konsulenttjenester, salg, markedsføring og
                    prosjektstyring.
                  </p>
                </Event>
                <Event
                  interval={"01.01.2021 - Nåværende"}
                  title={"Herregårdshuset AS"}
                  subtitle={"Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2054, Mogreina
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Utleie av hybler og
                    leiligheter
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Herregårdshuset er et hybelhus/Bed
                    & Breakfast på ca. 1000 kvm og 32 rom. Eiendommen er på 39
                    mål og har et av Norges største stabbur. Her eier/driver jeg
                    stedet og utvikler eiendommen med nye konsepter. Siden
                    overtakelse har vi klart å ha 90-100% belegg på utleien
                    hvert år.
                  </p>
                </Event>
                <Event
                  interval={"01.05.2019 – 01.05.2021"}
                  title={"REMA 1000 Rommen"}
                  subtitle={"Kjøpmann/ Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 0969, Oslo
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Kjøpmann i
                    dagligvarebutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Dagligvarebutikk på 65 mnok og 25
                    ansatte. Post i butikk og salg av fyrverkeri i tillegg.
                  </p>
                </Event>
                <Event
                  interval={"01.02.2016 – 01.05.2017"}
                  title={"Moody.no"}
                  subtitle={"Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2050, Jessheim
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Drift av nettbutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Nettbutikk for livstillprodukter
                    og klær fra hele verden. Prøvde oss på to andre typer
                    nettbutikker (moodystore.no og iento.com) også.
                  </p>
                </Event>
                <Event
                  interval={"01.06.2015 – 01.05.2019"}
                  title={"REMA 1000 Saga Senter"}
                  subtitle={"Kjøpmann/ Selvstendig næringsdrivende"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2050, Jessheim
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Kjøpmann i
                    dagligvarebutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Tok over butikk på 27 mnok i
                    omsetningen og 8 ansatte. Bygde butikken opp til 43 mnok i
                    omsetningen og 13 ansatte.
                  </p>
                </Event>
                <Event
                  interval={"01.06.2013 – 01.06.2015"}
                  title={"REMA 1000 Gystadparken"}
                  subtitle={"Avdelingsleder"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2050, Jessheim
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Jobb som butikkmedarbeider
                    i dagligvarebutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Jobbet i butikk etter skolen og i
                    helger
                  </p>
                </Event>
                <Event
                  interval={"03.10.2012 – 20.06.2013"}
                  title={"D'occasion UB"}
                  subtitle={"Daglig leder"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Daglig leder av
                    ungdomsbedrift
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Drev med brukhandel av alt fra
                    klær til elektronikk
                  </p>
                </Event>
                <Event
                  interval={"18.12.2011 – 01.06.2013"}
                  title={"REMA 1000 Gystadparken"}
                  subtitle={"Butikkmedarbeider"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2050, Jessheim
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Jobb som butikkmedarbeider
                    i dagligvarebutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Jobbet i butikk etter skolen og i
                    helger
                  </p>
                </Event>
                <Event
                  interval={"18.08.2011 – 30.05.2012"}
                  title={"Nes Dataservice UB"}
                  subtitle={"Daglig leder"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Daglig leder av
                    ungdomsbedrift
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Reparerte datautstyr til
                    privatpersoner og bedrifter
                  </p>
                </Event>
                <Event
                  interval={"26.06.2011 – 20.07.2011"}
                  title={"Nes Bo og Servicesenter"}
                  subtitle={"Renholder"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Jobb som renholder i
                    sykehjem
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Jobbet i butikk i forbindelse med
                    sommerferie
                  </p>
                </Event>
                <Event
                  interval={"19.10.2009 – 07.11.2009"}
                  title={"Expert Årnes"}
                  subtitle={"Butikkmedarbeider"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Jobb som butikkmedarbeider
                    i elektronikkbutikk
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Jobbet i butikk i forbindelse med
                    skoleprosjekt
                  </p>
                </Event>
                <Event
                  interval={"11.07.2008 – 31.07.2008"}
                  title={"Kløverenga Barnehage"}
                  subtitle={"Renholder"}
                >
                  <p className="pb-1">
                    <strong>Sted:</strong> 2150, Årnes
                  </p>
                  <p className="pb-1">
                    <strong>Arbeidsoppgaver:</strong> Renholder av barnehage
                  </p>
                  <p className="pb-1">
                    <strong>Notater:</strong> Jobbet i barnehage i forbindelse
                    med sommerferie
                  </p>
                </Event>
              </Timeline>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Erfaring;
