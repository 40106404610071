/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import innlegg2 from "../../../assets/innlegg/innlegg2/innlegg2-31.png";
import ImageSlider from "./Innlegg2a_Slideshow";

function Innlegg2a() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="col-lg-12 posts-list">
              <div className="single-post">
                <div className="feature-img">
                  <img className="img-fluid" src={innlegg2} alt="" />
                </div>
                <div className="blog_details">
                  <h1 style={{ color: "#2d2d2d" }}>
                    Å kaste loss: En sommer med frihet
                  </h1>
                  <ul className="blog-info-link ps-0 mt-3 mb-5">
                    <li>
                      <i className="bi bi-tags" />
                      Opplevelser
                    </li>
                    <li>
                      <i className="bi bi-person-fill" />
                      Sultan Avtajev
                    </li>
                    <li>
                      <i className="bi bi-stopwatch" />8 min
                    </li>
                  </ul>
                  <h2 style={{ color: "#2d2d2d" }}>Sommeren 2021</h2>
                  <p className="excert mt-1">
                    Det hele startet rett før sommeren 2021, da Corona-pandemien
                    var som verst. Jeg hadde alltid vært fascinert av tanken på
                    friheten en seilbåt kunne tilby – forestillingen om å kunne
                    utforske kystlinjer og åpne hav på egne premisser virket
                    utrolig tiltalende. Denne sommeren, uten reiseplaner grunnet
                    pandemien, ble den perfekte anledningen til å utforske denne
                    drømmen.
                  </p>
                  <p className="excert mt-1">
                    Så satt jeg en dag og tenkte: "Må det egentlig være så
                    kostbart å oppleve båtlivet?". Jeg spurte meg selv: "Hvor
                    billig kan jeg få det til?". Og som med alle mine
                    impulsbeslutninger, begynte jeg rett på gjennomføringen. I
                    dette øyeblikket, mens jeg sitter og skriver dette
                    innlegget, tenker jeg på dette med ideer. Alle de gangene
                    jeg har oppnådd noe, eller følt at jeg har oppnådd noe, har
                    alltid vært ved å bare starte rett på gjennomføring. Jeg har
                    selvfølgelig snublet noen ganger, men da har jeg i hvert
                    fall vært på vei. At jeg har snublet, betyr vel at jeg ikke
                    er på startstreken lenger, eller? Og det er vel godt nok i
                    mitt hode. Jeg sier ofte "litt og litt" til meg selv i hodet
                    mitt, og det hjelper meg å nullstille min tankegang,
                    spesielt hvis jeg har kveilet meg opp i noe eller hvis jeg
                    føler meg håpløs.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>Tilbake til båten...</h2>
                  <p className="excert mt-1">
                    Jeg tok et kjapt søk etter båter på finn.no og sorterte fra
                    "billigst til dyrest" og deretter fra "størst til minst".
                    Til min overraskelse fant jeg seilbåter som til og med var
                    gratis. De var selvfølgelig slitte eller gamle, men mange av
                    dem var fullt funksjonelle. Jeg bladde meg videre ned og
                    lagde samtidig en "favoritter" liste med de båtene jeg fant
                    interessante. Jeg satt til slutt igjen med 2-3 båter i
                    prisklassen 10-30k. Sendte melding til alle og prutet oppå
                    det hele. Fikk svar fra en av selgerne om at hvis jeg hentet
                    båten samme helg kunne jeg få hans båt til 18k. Den var vel
                    ute til rundt 30k hvis jeg ikke husker feil. Båten befant
                    seg i Sandefjord, en kystby en time fra Oslo. Båten kom med
                    en liten jolle, og begge båtene hadde en elmotor bak.
                  </p>
                  <p className="excert mt-1">
                    Men! Hva hadde jeg glemt? Jo, hva med båtplass? Det tenkte
                    jeg ikke så mye på, "det ordner seg nok på en eller annen
                    måte", antok jeg naivt. Ellers så hadde jeg funnet ut på
                    kartet at det var to øyer utenfor Sandvika. Dette fant jeg
                    ut på et eller annet båtforum. Mellom disse to øyene var det
                    vindstille da begge øyene sperret for vinden i hver sin
                    retning. Den eneste åpne siden mellom disse to øyene var mot
                    land. Man kan se øyene fra land da de ikke er mer enn
                    300-400m fra land. Så jeg fikk den geniale ideen om at hvis
                    vi ikke fikk leid en ledig båtplass, så kunne vi bare legge
                    båten til anker mellom disse to øyene og bruke jolla inn og
                    ut til land. Jeg hadde på denne tiden en varebil jeg brukte
                    til Herregårdshuset. Da kunne vi bare slenge jolla i
                    lasterommet når vi var ferdig, og slenge jolla ut igjen når
                    vi skulle på båten. Tanken var genial!
                  </p>
                  <p className="excert mt-1">
                    Jeg fikk med meg min kjæreste Paolla og mine venner Ayas og
                    Markus. Markus hadde en del erfaring med å seile, så det var
                    lurt å ha ham med. Jeg forstod ikke hvor lurt det var før
                    etter at vi hadde gjort opp for båten og den offisielt var
                    vår. Vi fikk til slutt kjøpt båten for 18.000 kr Nå hadde
                    jeg kjøpt båten, men hva starter jeg med? Hva er hva?
                    Hvordan seiler man? Hvordan bruker man motoren?
                    Hvordan vet jeg hvor dypt det er overalt i vannet?
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Seilbåter er en ingeniørkunst
                  </h2>
                  <p className="excert mt-1">
                    Det spesielle med seilbåter, og en av hovedgrunnene til at
                    de sjelden kantrer eller "flipper over", er deres dype kjøl.
                    Kjølen, ofte lastet med tung ballast, fungerer som et
                    stabiliserende element som motvirker vindens kraft på
                    seilene. Når vinden presser mot seilene og prøver å vippe
                    båten, gir kjølens vekt og motstanden den skaper under vann
                    en motkraft som holder båten oppreist. Dette gjør at
                    seilbåter kan takle selv store bølger og kraftig vind uten å
                    velte. En utfordring med dette designet er at kjølen
                    begrenser hvor nære båten kan komme land før det er fare for
                    å skrape bunnen. Derfor er kunnskap om vanndybden i det
                    området man seiler essensielt for å unngå grunnstøting.
                  </p>
                  <p className="excert mt-1">
                    For å navigere trygt, er seilbåter utstyrt med avansert
                    navigasjonsutstyr som et ekkolodd eller dybdemåler, som
                    viser vanndybden under båten, og en GPS for nøyaktig
                    posisjonering. Dette utstyret er uvurderlig for å planlegge
                    en sikker rute, spesielt i ukjente eller grunne farvann.
                  </p>
                  <p className="excert mt-1">
                    En annen moderne bekvemmelighet som har forbedret seilingen
                    betydelig, er autopiloten. Denne enheten kan festes til
                    roret og automatisk justere kursen for å holde båten på rett
                    kjøl, noe som reduserer behovet for konstant manuell
                    styring. Dette er spesielt nyttig på lengre overfarter, hvor
                    det å holde en konstant kurs kan være fysisk krevende. Både
                    ekkolodd, GPS, og autopilot fulgte med båten, noe som sparte
                    oss for å måtte investere i dette selv.
                  </p>
                  <p className="excert mt-1">
                    Markus' kunnskap og erfaring som seiler var uvurderlige for
                    oss, spesielt med tanke på de tekniske aspektene ved
                    seilbåten, som den dyptgående kjølen, bruk av
                    navigasjonsutstyr, og autopiloten. Han veiledet oss gjennom
                    de første utfordringene og ga oss en grundig forståelse av
                    seilingens grunnleggende prinsipper. Markus demonstrerte
                    effektiv bruk av teknologien om bord, og hans innsikt i
                    seilbåtens design, spesielt kjølens essensielle rolle for
                    stabilitet, var avgjørende for vår trygghet og komfort.
                    Etter å ha satt kursen mot Oslo og forlatt marinaen i
                    Sandefjord, tok det oss rundt to-tre timer før vi var
                    utenfor Oslofjorden.
                  </p>
                  <p className="excert mt-1"></p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Som et velsmurt maskineri
                  </h2>
                  <p className="excert mt-1">
                    Angående dette med båtplass, jeg fant jo selvfølgelig ikke
                    noen ledige båtplasser i tide, og det ble til at den geniale
                    reserveplanen min med anker mellom to øyer måtte
                    iverksettes. Og vet du hva? Det fungerte som en velsmurt
                    maskin. Den eneste ulempen var at vi måtte bruke to turer
                    med jolla når vi hadde med venner og flere poser med mat.
                    Vår improviserte båtplass fungerte utmerket. Sommeren ble
                    fylt med uforglemmelige overnattinger, badeturer og middager
                    under åpen himmel. Det var en sommer som tilbød en smak av
                    frihet og eventyr, og inspirerte til videre drømmer om
                    båtlivet. Et vennepar av meg og Paolla beskrev en av turene
                    med overnatting som blant de beste opplevelsene de hadde
                    hatt i livet. Når jeg tenker tilbake på denne sommeren, så
                    var dette en av de beste opplevelsene jeg hadde hatt
                    også. Det var som å få førerkortet til en bil og vite at
                    hvis du ville, så kunne du kjøre hvor enn du ville i verden.
                    En følelse av frihet. Helt siden da har jeg lengtet tilbake.
                    Denne lengselen har jeg ikke bare lagt fra meg, men bygd på
                    videre og jobbet med. Jeg har lagt en plan. Mer om denne
                    planen i et annet innlegg.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>"Winter is coming"</h2>
                  <p className="excert mt-1">
                    Dette systemet med å ta jolla inn og ut fra land fungerte så
                    bra at jeg fikk til og med en annen genial idé, og det var:
                    “Hva om vi tilbyr overnatting på båten med mat inkludert? En
                    middag med partneren der alt er klargjort for gjestene. Da
                    kunne vi bruke jolla til å kjøre gjestene ut til båten og
                    hente dem neste dag”. Jeg kom aldri så langt, da august
                    måned kom fort. Men dette hadde jo ikke jeg tenkt på? Hvor i
                    alle dager skulle jeg plassere båten nå som høsten og
                    vinteren kom?
                  </p>
                  <p className="excert mt-1">
                    Jeg kom på at det enkleste ville være å prøve å selge den
                    igjen, så jeg la den ut til salgs på finn.no. I mellomtiden
                    la jeg en plan B, og det var å seile den til et varmere land
                    og parkere/ankre den opp der. For så å ta flyet hjem igjen.
                    Jeg tenkte at vi kunne bruke den som en “utenlands”
                    leilighet. Dessverre fikk vi ikke opplevd denne plan B, og
                    ei dame ville kjøpe den. Båten ble til slutt solgt for 25k i
                    midten av august. Dette var også hennes første båt.
                  </p>
                  <h2 style={{ color: "#2d2d2d" }}>
                    Grip dagen og kast deg ut i det!
                  </h2>
                  <p className="excert mt-1">
                    I hvert øyeblikk av denne reisen, fra de første skrittene på
                    dekk til den siste solnedgangen over horisonten, oppdaget
                    jeg ikke bare havets uendelige skjønnhet, men også sjelens
                    tørst etter frihet og eventyr. Å navigere gjennom bølgene,
                    under stjernekledde himler, lærte meg verdien av å våge inn
                    i det ukjente, viktigheten av å være forberedt på alt, og
                    den uerstattelige gleden av å dele disse magiske øyeblikkene
                    med menneskene jeg bryr meg om. Denne opplevelsen har vevet et
                    dypt bånd med havet inn i mitt hjerte, og tent en flamme av
                    eventyrlyst.
                  </p>
                  <p className="excert mt-1">
                    Mens jeg nå tar en pause fra sjømannslivet, føler jeg i mitt
                    innerste at dette bare er begynnelsen. Havet kaller på meg
                    med sine uutforskede dyp og uendelige horisonter, og jeg vet
                    at jeg igjen vil sette seil mot nye eventyr.
                  </p>
                  <p className="excert mt-1">
                    Så til deg som drømmer om å kaste loss mot det ukjente, grip
                    dagen, kast deg ut i det, og la hjertet lede veien mot de
                    drømmene du tørster etter å utforske. Og ja, uansett hvor du
                    seiler, gjør sikkerheten til mannskapet til din høyeste
                    prioritet; ikke glem redningsvestene! Der fremme venter et
                    hav av uforglemmelige minner, bundet sammen av båndene av
                    frihet og fellesskap.
                  </p>
                  {/* Slideshow */
                  <ImageSlider />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Innlegg2a;
