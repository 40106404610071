/* eslint-disable */
import React from "react";
import "./Bøker.css";
import booksData from "../../data/boker.json"; // Importer bøkene

import bok1 from "../../assets/bøker/bok1.jpg";
import bok2 from "../../assets/bøker/bok2.jpg";
import bok3 from "../../assets/bøker/bok3.jpg";
import bok4 from "../../assets/bøker/bok4.jpg";
import bok5 from "../../assets/bøker/bok5.jpg";
import bok6 from "../../assets/bøker/bok6.jpg";
import bok7 from "../../assets/bøker/bok7.jpg";
import bok8 from "../../assets/bøker/bok8.jpg";
import bok9 from "../../assets/bøker/bok9.jpg";
import bok10 from "../../assets/bøker/bok10.jpg";
import bok11 from "../../assets/bøker/bok11.jpg";
import bok12 from "../../assets/bøker/bok12.jpg";
import bok13 from "../../assets/bøker/bok13.jpg";
import bok14 from "../../assets/bøker/bok14.jpg";
import bok15 from "../../assets/bøker/bok15.jpg";
import bok16 from "../../assets/bøker/bok16.jpg";
import bok17 from "../../assets/bøker/bok17.jpg";
import bok18 from "../../assets/bøker/bok18.jpg";
import bok19 from "../../assets/bøker/bok19.jpg";
import bok20 from "../../assets/bøker/bok20.jpg";
import bok21 from "../../assets/bøker/bok21.jpg";
import bok22 from "../../assets/bøker/bok22.jpg";
import bok23 from "../../assets/bøker/bok23.jpg";
import bok24 from "../../assets/bøker/bok24.jpg";
import bok25 from "../../assets/bøker/bok25.jpg";
import bok26 from "../../assets/bøker/bok26.jpg";
import bok27 from "../../assets/bøker/bok27.jpg";
import bok28 from "../../assets/bøker/bok28.jpg";
import bok29 from "../../assets/bøker/bok29.jpg";
import bok30 from "../../assets/bøker/bok30.jpg";
import bok31 from "../../assets/bøker/bok31.jpg";
import bok32 from "../../assets/bøker/bok32.jpg";
import bok33 from "../../assets/bøker/bok33.jpg";
import bok34 from "../../assets/bøker/bok34.jpg";
import bok35 from "../../assets/bøker/bok35.jpg";
import bok36 from "../../assets/bøker/bok36.jpg";
import bok37 from "../../assets/bøker/bok37.jpg";
import bok38 from "../../assets/bøker/bok38.jpg";
import bok39 from "../../assets/bøker/bok39.jpg";
import bok40 from "../../assets/bøker/bok40.jpg";
import bok41 from "../../assets/bøker/bok41.jpg";
import bok42 from "../../assets/bøker/bok42.jpg";

const bokBilder = {
  bok1: bok1,
  bok2: bok2,
  bok3: bok3,
  bok4: bok4,
  bok5: bok5,
  bok6: bok6,
  bok7: bok7,
  bok8: bok8,
  bok9: bok9,
  bok10: bok10,
  bok11: bok11,
  bok12: bok12,
  bok13: bok13,
  bok14: bok14,
  bok15: bok15,
  bok16: bok16,
  bok17: bok17,
  bok18: bok18,
  bok19: bok19,
  bok20: bok20,
  bok21: bok21,
  bok22: bok22,
  bok23: bok23,
  bok24: bok24,
  bok25: bok25,
  bok26: bok26,
  bok27: bok27,
  bok28: bok28,
  bok29: bok29,
  bok30: bok30,
  bok31: bok31,
  bok32: bok32,
  bok33: bok33,
  bok34: bok34,
  bok35: bok35,
  bok36: bok36,
  bok37: bok37,
  bok38: bok38,
  bok39: bok39,
  bok40: bok40,
  bok41: bok41,
  bok42: bok42,
};

function Bøker() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div class="row">
            {booksData.map((book, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div
                  className="single-album-area wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="album-thumb">
                    {/* Anta at book.bilde er 'bok1', 'bok2', etc. */}
                    <img src={bokBilder[book.Bilde]} alt={book.Navn} />
                  </div>
                  <div className="album-info">
                    <a
                      href={book.Link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h5>{book.Navn}</h5>
                    </a>
                    <p>{book.Forfatter}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Bøker;
