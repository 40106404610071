/* eslint-disable */
import React from "react";
import "./Avis.css";

import avis1 from "../../assets/avis/avis1.jpg";
import avis2 from "../../assets/avis/avis2.jpg";
import avis3 from "../../assets/avis/avis3.jpg";
import avis4 from "../../assets/avis/avis4.jpg";
import avis5 from "../../assets/avis/avis5.jpeg";
import avis6 from "../../assets/avis/avis6.jpg";
import avis7 from "../../assets/avis/avis7.jpg";

function Avis() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div class="about_section layout_padding">
            <div class="container-fluid">
              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Salg av Birkelund Institusjon</h1>
                    <p class="about_text">
                      Kjøpte tomten for 5 millioner kroner, vil selge for over
                      det dobbelte.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.eub.no/kjopte-tomten-for-5-millioner-kroner-vil-selge-for-over-det-dobbelte/s/5-136-605304"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis7} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Kjøp av Birkelund Institusjon</h1>
                    <p class="about_text">
                      Sultan (29) har kjøpt den gamle institusjons-eiendommen.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.eub.no/sultan-29-har-kjopt-den-gamle-institusjons-eiendommen-dette-er-et-spennende-prosjekt/s/5-136-473332?fbclid=IwAR3earOmEKHxxPCXIGx3RJKkIu-D57nXUg1K9vAEbrceSPQmFb_reOecZNQ"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis2} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Førstemann til Lofoten</h1>
                    <p class="about_text">
                      Paolla og kjæresten Sultan skal kjempe mot venner i en
                      svært spesiell reisekonkurranse.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.eub.no/paolla-og-kjaresten-sultan-skal-kjempe-mot-venner-i-en-svart-spesiell-reisekonkurranse-det-blir-nok-a-ga-litt-ut-av-komfortsonen-for-meg/s/5-136-307797"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis5} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Førstemann til Lofoten</h1>
                    <p class="about_text">
                      Tok seg fra Dal til Lofoten uten penger, fly eller hjelp
                      fra kjente: – Jeg tror vi kunne reist over hele Europa på
                      denne måten.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.eub.no/tok-seg-fra-dal-til-lofoten-uten-penger-fly-eller-hjelp-fra-kjente-jeg-tror-vi-kunne-reist-over-hele-europa-pa-denne-maten/s/5-136-308751"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis6} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Kjøp av Herregårdshuset på Dal</h1>
                    <p class="about_text">
                      Sultan (29) kjedet seg og tok et søk på Finn. Det endte
                      med kjøp av en gammel Herregård på Dal. Publisert av
                      Eidsvoll Ullensaker Blad.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.eub.no/sultan-29-kjedet-seg-og-tok-et-sok-pa-finn-det-endte-med-kjop-av-en-gammel-herregard-pa-dal/s/5-136-457697"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis1} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Kan være yngst i REMA-kjeden</h1>
                    <p class="about_text">
                      Bare 21 år gammel kan Sultan Avtajev være den yngste
                      kjøpmannen i Rema 1000-kjeden.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.glomdalen.no/kan-vare-yngst-i-rema-kjeden/s/5-19-84606"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis3} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>

              <div class="row avis">
                <div class="col-12 col-lg-6 text-first">
                  <div class="about_taital_main">
                    <h1 class="about_taital">Åpen 18 timer i døgnet</h1>
                    <p class="about_text">
                      Sprenger åpningstidene på Jessheim.{" "}
                    </p>
                    <div class="readmore_bt">
                      <a
                        href="https://www.rb.no/nyheter/jessheim/sprenger-apningstidene-pa-jessheim/s/5-43-109283"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Les artikkel
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 padding_right_0">
                  <div>
                    <img src={avis4} alt="" class="about_img"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Avis;
