/* eslint-disable */
import React, { useState } from "react";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./Nedlasting.css";

// Anta dette er din dokumentliste hentet fra din database
const dokumenter = [
  { id: "1", navn: "Sultan Avtajev Arbeidsstil test" },
  { id: "2", navn: "Sultan Avtajev Attest Lederkurs" },
  { id: "3", navn: "Sultan Avtajev Attest Økonomikurs" },
  { id: "4", navn: "Sultan Avtajev Jung Type Index" },
  { id: "5", navn: "Sultan Avtajev Karakterutskrift Dataing" },
  { id: "6", navn: "Sultan Avtajev LinkedIn CV" },
  { id: "7", navn: "Sultan Avtajev Scales test" },
  { id: "8", navn: "Sultan Avtajev Switch test" },
  { id: "9", navn: "Sultan Avtajev Vitnemål Grunnskolen" },
  { id: "10", navn: "Sultan Avtajev Vitnemål VGS" },
  // Legg til flere dokumenter etter behov
];

function Nedlasting() {
  const [passord, setPassord] = useState({});
  const [error, setError] = useState({});
  const [downloadUrls, setDownloadUrls] = useState({}); // Ny tilstand for lagring av URL-er

  const handlePassordChange = (id, value) => {
    setPassord((prev) => ({ ...prev, [id]: value }));
  };

  const sjekkPassord = async (fileId) => {
    const app = getApp();
    const functions = getFunctions(app);
    const validatePasswordAndGetFile = httpsCallable(
      functions,
      "validatePasswordAndGetFile"
    );

    try {
      const result = await validatePasswordAndGetFile({
        filnavn: fileId,
        password: passord[fileId],
      });
      if (result.data.url) {
        setDownloadUrls((prevUrls) => ({
          ...prevUrls,
          [fileId]: result.data.url, // Lagrer URL i tilstanden
        }));
        setError((prevErrors) => ({ ...prevErrors, [fileId]: "" })); // Tømmer feilmeldingen for dette fileId
      }
    } catch (error) {
      console.error("Error verifying password:", error);
      // Setter feilmeldingen for det spesifikke fileId
      setError((prevErrors) => ({
        ...prevErrors,
        [fileId]: "Feil passord!",
      }));
    }
  };

  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row">
            <div className="hoved col-md-12">
              <h1 className="h2 text-center">Nedlasting av filer</h1>
              {/* Ny beskrivelsestekst */}
              <div className="download-description text-dark">
                <p className="p-heading">
                  For å laste ned ønskede dokumenter, vennligst oppgi passordet
                  for det aktuelle dokumentet og klikk deretter på "Sjekk
                  passord". Vent noen sekunder. Om passordet er riktig, vil en
                  "Last ned"-knapp vises. Dersom du ikke har et passord, eller
                  opplever problemer med nedlastingen, vennligst ta kontakt på{" "}
                  <a href="mailto:sultan@developia.no">sultan@developia.no</a>{" "}
                  for assistanse og for å få tilsendt passord.
                </p>
                
              </div>
              {dokumenter.map((dok) => (
                <div key={dok.id} className="download-item">
                  <input
                    className="inputpw"
                    type="password"
                    placeholder="Passord"
                    value={passord[dok.id] || ""}
                    onChange={(e) =>
                      handlePassordChange(dok.id, e.target.value)
                    }
                  />
                  <div className="div-knapper">
                    <button
                      className="sjekkeknapp btn btn-dark btn-md"
                      onClick={() => sjekkPassord(dok.id)}
                    >
                      Sjekk passord
                    </button>
                    {downloadUrls[dok.id] && (
                      <button
                        className="lasteknapp btn btn-dark btn-md"
                        onClick={() =>
                          window.open(downloadUrls[dok.id], "_blank")
                        }
                      >
                        Last ned
                      </button>
                    )}
                  </div>
                  <span className="filnavn text-dark">{dok.navn}</span>
                  {error[dok.id] && (
                    <p className="feilmelding error text-danger">
                      {error[dok.id]}
                    </p>
                  )}{" "}
                  {/* Viser feilmeldingen for hvert dokument basert på fileId */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Nedlasting;
