/* eslint-disable */
import React from "react";
import { VectorMap } from "react-jvectormap";

const mapData = {
  AZ: 500,
  GE: 500,
  ID: 500,
  QA: 500,
  RU: 500,
  TR: 500,
  VN: 500,
  AT: 500,
  DK: 500,
  NO: 500,
  FI: 500,
  SE: 500,
  FR: 500,
  DE: 500,
  IT: 500,
  LV: 500,
  NL: 500,
  PL: 500,
  ES: 500,
};

const handleClick = (e, countryCode) => {
  console.log(countryCode);
};

function Reise() {
  console.log("Reise-komponenten rendres");
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <VectorMap
            map={"world_mill"}
            backgroundColor="transparent" //change it to ocean blue: #0077be
            zoomOnScroll={false}
            containerStyle={{
              width: "100%",
              height: "30rem",
            }}
            onRegionClick={handleClick} //gets the country code
            containerClassName="map"
            regionStyle={{
              initial: {
                fill: "#e4e4e4",
                "fill-opacity": 0.9,
                stroke: "none",
                "stroke-width": 0,
                "stroke-opacity": 0,
              },
              hover: {
                "fill-opacity": 0.8,
                cursor: "pointer",
              },
              selected: {
                fill: "#2938bc", //color for the clicked country
              },
              selectedHover: {},
            }}
            regionsSelectable={true}
            series={{
              regions: [
                {
                  values: mapData, //this is your data
                  scale: ["#146804", "#ff0000"], //your color game's here
                  normalizeFunction: "polynomial",
                },
              ],
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default Reise;
