/* eslint-disable */
import React from "react";
import "./ProgressLabel.css";

const SKILL_LEVELS = ["1", "2", "3", "4", "5"];

function ProgressLabel() {
  return (
    <div className="skill-levels">
      <div className="skill-levels-name"></div>
      <div className="skill-levels-progress">
        {SKILL_LEVELS.map((level) => (
          <span key={level} className="skill-levels-label">
            {level}
          </span>
        ))}
      </div>
    </div>
  );
}

export default ProgressLabel;
