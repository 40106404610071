/* eslint-disable */
import React from "react";
import { SocialIcon } from "react-social-icons";

function SocialIcons() {
  return (
    <div style={{ marginTop: "50px" }}>
      <SocialIcon
        url="https://twitter.com/sultanavtajev"
        style={{
          marginRight: "6px",
          marginBottom: "10px",
          height: "20px",
          width: "20px",
        }}
      />
      <SocialIcon
        url="https://www.facebook.com/sultan.avtajev"
        style={{
          marginRight: "6px",
          marginBottom: "10px",
          height: "20px",
          width: "20px",
        }}
      />
      <SocialIcon
        url="https://www.linkedin.com/in/sultan-avtajev-7a81abb3/"
        style={{
          marginRight: "6px",
          marginBottom: "10px",
          height: "20px",
          width: "20px",
        }}
      />
      <SocialIcon
        url="https://www.instagram.com/sultanavtajev/"
        style={{
          marginRight: "6px",
          marginBottom: "10px",
          height: "20px",
          width: "20px",
        }}
      />
      <SocialIcon
        url="https://www.youtube.com/@euchello3455"
        style={{
          marginRight: "6px",
          marginBottom: "10px",
          height: "20px",
          width: "20px",
        }}
      />
      <SocialIcon
        url="https://github.com/sultanavtajev"
        style={{ marginBottom: "10px", height: "20px", width: "20px" }}
      />
    </div>
  );
}

export default SocialIcons;
